
<template>
	<div class="sides">
<h3   class="index-menu-title">精选爆款</h3>
	<ul class="menu">
		<li
	     @mouseenter="mouseenter(4)"
         @mouseleave="mouseleave(4)"		 
        >
			<div class="side_tit">
		      	<router-link class="nen_tits"  to="">App安全</router-link>
		      	<p>
					<span @mouseenter="mouseenter(8)">
		      	  <router-link  to="">App技术检测服务</router-link>
				  </span>
		          <el-divider direction="vertical"></el-divider>
				  <span @mouseenter="mouseenter(4)">
		          <router-link  to="">APP合规检测</router-link>
				  </span>
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 8 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
	        		    <li><router-link target="_blank" to="/appinfiltration">APP渗透测试</router-link></li>
	        		    <li><router-link target="_blank" to="/app2">App安全检测高级版</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
	        <div class="menu_box"  :class="{ active3: 4 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
	        		    <li><router-link target="_blank" to="/app1">App安全合规基础版</router-link></li>
		        		<li><router-link target="_blank" to="/app">App个人隐私合规高级版</router-link></li>
	        		    <li><router-link target="_blank" to="/app3">App安全无忧版</router-link></li>
	        		    <li><router-link target="_blank" to="/app4">App预防下架安全保障套餐(被下架全额退款)</router-link></li>
	        		    <li><router-link target="_blank" to="/ability">APP安全能力第三方评估报告（APP在应用市场上架时需要）</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
		</li>
		<li
	      @mouseenter="mouseenter(0)"
         @mouseleave="mouseleave(0)"
        >
			<div class="side_tit">
				<router-link class="nen_tits"  to="">等级保护</router-link>
		      	<p>
					<span @mouseenter="mouseenter(9)">
		      	   <router-link to="">等保测评</router-link>
					</span>
		           <el-divider direction="vertical"></el-divider>
				   <span @mouseenter="mouseenter(0)">
		           <router-link  to="">等保整改及产品</router-link>
				   </span>
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 9 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li><router-link target="_blank" to="/details">等保测评</router-link></li>
		        		<li><router-link target="_blank" to="/dengbaozhengming">等保咨询-定级备案服务</router-link></li>
		        		<li><router-link target="_blank" to="/security">等保合规安全解决方案</router-link></li>
		        		<li><router-link target="_blank" to="/yitihua">等保一体化解决方案</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>

	        </div>
	        <div class="menu_box"  :class="{ active3: 0 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
	        		    <li><router-link target="_blank" to="/dengbaozhenggai">等保技术整改</router-link></li>
	        		    <li><router-link target="_blank" to="/dengbaozhenggai">等保管理制度整改</router-link></li>
		        		<li><router-link target="_blank" to="/dbhgo">等保二级安全合规产品（云上系统）</router-link></li>
		        		<li><router-link target="_blank" to="/dbhge">等保三级安全合规产品（云上系统）</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">

	        	</div>

	        </div>
		</li>
		<li 
		@mouseenter="mouseenter(1)"
        @mouseleave="mouseleave(1)"
        >
			<div class="side_tit">
				<router-link class="nen_tits"  to="">风险评估</router-link>
		      	<p>
					<span @mouseenter="mouseenter(1)"
         			>
		      	  <router-link  to="">风险评估</router-link>
					</span>
		          <!-- <el-divider direction="vertical"></el-divider> -->
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 1 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li> <router-link target="_blank"  to="/sys">网络信息安全风险评估服务</router-link></li>
		        		<li> <router-link target="_blank"  to="/pg">第三方网络安全风险评估服务</router-link></li>
		        		<li> <router-link target="_blank" to="/ruwangsafe">入网安全评估</router-link></li>
		        		<li> <router-link target="_blank"  to="/zice">ICP/EDI自测评风险评估</router-link></li>
		        		<li> <router-link target="_blank"  to="/icpedi">ICP/EDI第三方风险评估</router-link></li>
		        		<li> <router-link target="_blank"  to="/assessment">安全评估报告（全国互联网安全管理服务平台）</router-link></li>
		        		<li> <router-link target="_blank"  to="/newnet">互联网新技术新业务风险评估</router-link></li>
		        		<!-- <li> <router-link target="_blank"  to="">网约车业务系统风险评估服务</router-link></li> -->
		        		<li> <router-link target="_blank"  to="/wangyueche">网约车APP安全保障能力报告</router-link></li>
		        		<li> <router-link target="_blank"  to="/yulun">具有舆论属性的风险评估</router-link></li>
						 <li> <router-link target="_blank"  to="/sjcj">数据出境合规服务</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
	       
		</li>
	<li 
		@mouseenter="mouseenter(7)"
        @mouseleave="mouseleave(7)"
        >
			<div class="side_tit">
				<router-link class="nen_tits"  to="">通信网络单元定级备案</router-link>
		      	<p>
					<span @mouseenter="mouseenter(7)">
		          		<router-link to="">定级备案</router-link>
					</span>
		          <!-- <el-divider direction="vertical"></el-divider> -->
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 7 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
						<li><router-link target="_blank" to="/car">车联网网络安全防护定级备案</router-link></li>
						<li><router-link target="_blank" to="/zhejiang">浙江省通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/jiangsu">江苏省通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/beijing">北京市通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/guangdong">广东省通信网络单元定级备案</router-link></li>
		        		<li><router-link target="_blank" to="/hubei">湖北省通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/shanxi">陕西省通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/jiangxi">江西省通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/shanghai">上海市通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/tianjin">天津市通信网络单元定级备案</router-link></li>
						<li><router-link target="_blank" to="/shenzhen">深圳市通信网络单元定级备案</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
		</li>
		<li
	      @mouseenter="mouseenter(2)"
         @mouseleave="mouseleave(2)"
        >
			<div class="side_tit">
		      	<router-link class="nen_tits"  to="">安全服务</router-link>
		      	<p>
					<span @mouseenter="mouseenter(10)">
		      	  <router-link  to="">安全保障</router-link>
				  </span>
		          <el-divider direction="vertical"></el-divider>
				  <span @mouseenter="mouseenter(2)">
		         <router-link  to="">安全检测</router-link>
				  </span>
		      	</p>
	        </div>
	        <!-- <div class="menu_box"  :class="{ active3: 2 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li> <router-link target="_blank" to="/yunsafe">初级套餐</router-link></li>
		        		<li> <router-link target="_blank" to="/yunsafe1">中级套餐</router-link></li>
		        		<li> <router-link target="_blank" to="/yunsafe2">高级套餐</router-link></li>
	        		    <li> <router-link target="_blank" to="/yunsafe3">定制套餐</router-link></li>
		        		<li> <router-link target="_blank" to="">漏洞扫描</router-link></li>
		        		<li> <router-link target="_blank" to="">渗透测试</router-link></li>
		        		<li> <router-link target="_blank" to="">漏洞处置方案报告</router-link></li>
		        		<li> <router-link target="_blank" to="">漏洞修复</router-link></li>
		        		<li> <router-link target="_blank" to="">安全专家应急响应</router-link></li>
		        		<li> <router-link target="_blank"  to="/outsource">安全服务外包</router-link></li>
		        		<li> <router-link target="_blank"  to="/yunwei">安全运维服务</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div> -->
	        <div class="menu_box"  :class="{ active3: 10 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li> <router-link target="_blank" to="/guarantee">安全保障服务</router-link></li>
		        		<li> <router-link target="_blank" to="/tightening">安全预警通报</router-link></li>
		        		<li> <router-link target="_blank" to="/inspection">安全巡检服务</router-link></li>
		        		<li> <router-link target="_blank" to="/epiboly">安全服务外包</router-link></li>
		        		<li> <router-link target="_blank" to="/reinforce">安全加固服务</router-link></li>
		        		<li> <router-link target="_blank" to="/monitor">网站安全监测服务</router-link></li>
		        		<li> <router-link target="_blank" to="/response">应急响应</router-link></li>
		        		<li> <router-link target="_blank" to="/operation">安全运维服务</router-link></li>
		        		<li> <router-link target="_blank" to="/defense">安全攻防培训服务</router-link></li>
		        		<li> <router-link target="_blank" to="/consciousness">信息安全理念和意识培训</router-link></li>
		        		<li> <router-link target="_blank" to="/iso">ISO27001信息安全管理体系认证咨询服务</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
	        <div class="menu_box"  :class="{ active3: 2 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li> <router-link target="_blank" to="/appinfiltration">APP渗透测试</router-link></li>
		        		<li> <router-link target="_blank" to="/webinfiltration">WEB渗透测试</router-link></li>
		        		<li> <router-link target="_blank" to="/infiltration">系统渗透测试</router-link></li>
		        		<li> <router-link target="_blank" to="/webscanning">WEB漏洞扫描</router-link></li>
		        		<li> <router-link target="_blank" to="/scanning">系统漏洞扫描</router-link></li>
		        		<li> <router-link target="_blank" to="/webinspect">WEB安全健康检查</router-link></li>
		        		<li> <router-link target="_blank" to="/audit">代码审计</router-link></li>
		        		<li> <router-link target="_blank" to="/inspect">基线扫描及配置核查</router-link></li>
		        		
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
		</li>
		<li
	      @mouseenter="mouseenter(3)"
         @mouseleave="mouseleave(3)"
        >
			<div class="side_tit">
		      	<router-link class="nen_tits"   to="">安全产品</router-link>
		      	<p>
					<!-- <span @mouseenter="mouseenter(11)">
		      	  <router-link  to="">线下安全产品</router-link>
					</span> -->
		          <!-- <el-divider direction="vertical"></el-divider> -->
				  <span @mouseenter="mouseenter(3)">
		          <router-link  to="">云上安全产品</router-link>
				  </span>
		      	</p>
	        </div>
	        <!-- <div class="menu_box"  :class="{ active3: 11 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        	
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div> -->
	        <div class="menu_box"  :class="{ active3: 3 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li><router-link target="_blank" to="/firewall">云防火墙</router-link></li>
		        		<li> <router-link target="_blank" to="/fort">云堡垒机</router-link></li>
		        	    <li> <router-link target="_blank" to="/safeCenter">云安全中心</router-link></li>
	        		    <li> <router-link target="_blank" to="/log">云日志审计</router-link></li>
		        	    <!-- <li> <router-link target="_blank" to="/audit">代码审计</router-link></li> -->
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        	</div>
	        </div>
		</li>

		<li
	      @mouseenter="mouseenter(5)"
         @mouseleave="mouseleave(5)"
        >
			<div class="side_tit">
		      	<router-link class="nen_tits"  to="">合作产品</router-link>
		      	<p>
					<span @mouseenter="mouseenter(12)">
		      	 <router-link to="">ICP/EDI许可证</router-link>
					</span>
		          <el-divider direction="vertical"></el-divider>
				  <span @mouseenter="mouseenter(5)">
		          <router-link to="">网约车许可证</router-link>
				  </span>
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 12 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		
		        		<li><router-link target="_blank" to="/icp">ICP许可证申办咨询服务</router-link></li>
		        		<li><router-link target="_blank" to="/edi">EDI许可证申办咨询服务</router-link></li>
		        		
<!--  -->
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
<!--  -->
	        	</div>
<!--  -->
	        </div>
	        <div class="menu_box"  :class="{ active3: 5 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<!-- <li><router-link target="_blank" to="/icpedihandle">ICP/EDI办证</router-link></li> -->
		        		
		        		<li><router-link target="_blank" to="/chepai">网约车许可证申办咨询服务</router-link></li>
<!--  -->
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
<!--  -->
	        	</div>
<!--  -->
	        </div>
		</li>
		<!-- <li
	      @mouseenter="mouseenter(6)"
         @mouseleave="mouseleave(6)"
        >
			<div class="side_tit">
		      	<router-link class="nen_tits"  to="">等保测评机构</router-link>
		      	<p>
		      	  <router-link  to="">按地区</router-link>
		          <el-divider direction="vertical"></el-divider>
		         <router-link  to="">测评机构</router-link>
		      	</p>
	        </div>
	        <div class="menu_box"  :class="{ active3: 6 != current1 }">
	        	<div class="menu_box_left">
	        		<h4>全部产品</h4>
		        	<ul>
		        		<li><router-link target="_blank" to="/chepai">网约车牌照办理</router-link></li>
	        		    <li><router-link  to="">测评机构</router-link></li>
		        	</ul>
	        	</div>
	        	<div class="menu_box_right">
	        		<h4>全部产品</h4>
	        		<dl>
	        			<dt>
	        				<a  href="/">
	        					<p>高配云服务器免费试用</p>
	        					<span>了解详情</span>
	        					<img src="@/assets/images/a01.png">
	        				</a>
	        			</dt>
	        			<dt>
	        				<a  href="/">
	        					<p>高配云服务器免费试用</p>
	        					<span>了解详情</span>
	        					<img src="@/assets/images/a01.png">
	        				</a>
	        			</dt>
	        			<dt>
	        				<a  href="/">
	        					<p>高配云服务器免费试用</p>
	        					<span>了解详情</span>
	        					<img src="@/assets/images/a01.png">
	        				</a>
	        			</dt>
	        		</dl>
	        	</div>

	        </div>
		</li> -->
		<!-- @mouseenter="mouseenter(0)" @mouseleave="mouseleave(1)" -->
    <li v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==2">
      <div  >class="side_tit"
        <router-link class="nen_tits"  :to="childmenu.menUrl"  @mouseenter="abc=true" @mouseleave="abc=false">{{ childmenu.menName }}</router-link>
       <p v-show="abc">
         <router-link v-for="(childmenu,key) in childmenus" :key=key target="_blank" :to="menu.menUrl">{{ childmenu.menName }}</router-link>
         <el-divider direction="vertical"></el-divider>
         <router-link target="_blank"  to="details">等保三级</router-link>
         <el-divider direction="vertical"></el-divider>
         <router-link target="_blank" to="/dengbaomeal">等保套餐</router-link>
       </p>
      </div>
      <div class="menu_box" :class="{ active3: 0 != current1 }">-->
        <div class="menu_box_right">
          <h4>{{ childmenu.menName }}</h4>
          <ul>
           <li v-for="(childofmenu,key) in childofmenus" :key=key ><router-link v-show="childofmenu.menParentId==childmenu.menId" target="_blank" :to="childofmenu.menUrl">{{ childofmenu.menName }}</router-link></li>
            <li><router-link target="_blank" to="/yitihua">等保整改</router-link></li>
            <li><router-link target="_blank" to="/dengbaomeal">安全产品</router-link></li>

            <li><router-link target="_blank" to="/dengbaohegui">等保合规套餐</router-link></li>
            <li><router-link target="_blank" to="/yitihua">等保一体化解决方案</router-link></li>
            <li><router-link target="_blank" to="/dengbaozhengming">等级保护备案证明</router-link></li>
            <li><router-link target="_blank" to="/dengbao2">云业务系统安全产品套餐(二级等保)</router-link></li>
            <li><router-link target="_blank" to="/dengbao3">云业务系统安全产品套餐(三级等保)</router-link></li>
          </ul>
        </div>
        <div class="menu_box_right">

        </div>

      </div>
    </li>
  </ul>
	<h3  class="index-menu-title more">查看更多</h3>
  </div>
</template>


<script type="text/javascript">
export default{
	data(){
		return{
			current1:100,
      menus:[],
      childmenus:[],
      childofmenus:[]
		}
	},
	mounted(){
		if(this.$route.path=="/"){
			this.current1=100
		}else{
			this.current1=0
		}
		// console.log(this.$route.path)
	},
	methods:{
		handleOpen(key, keyPath) {
	      console.log(key, keyPath);
	    },
	    handleClose(key, keyPath) {
	      console.log(key, keyPath);
	    },
	    mouseenter(num) {
	      this.current1 = num;
	    },
	    mouseleave() {
	      this.current1 = 1000;
	    },
    page(currentPage){
      const _this = this
      let modelid="2";
      _this.$axios.get("/menusWithModelId?modelid=" + modelid).then(res => {
        var data = res.data.data
        _this.menus = data
        _this.childmenus=data
        _this.childofmenus=data
        _this.$message({
          message: _this.childmenus,
          type: 'success',
        });
      })
    }

	},
  created() {
    this.page(1)
  }


};
</script>

<style type="text/css" scoped>
.sides{
	height:555px;
	background: #fff;
  position:absolute;
  /* padding-top:15px; */
  width: 240px;
}
.index-menu-title {
  background-color: #0e82cb;
  color: white;
  text-align:center;
  font-size:14px;
  line-height: 2;
  font-weight: 600;
  line-height: 30px;
  margin:0;
}
.more{
	cursor:pointer;
	padding-bottom:3px;
	line-height: 33px;
}
.menu{
	padding-top:0px;
	position: relative;
	box-sizing: border-box;
}
.menu>li{
	padding: 15px 16px 0;
	height:70px;
	padding-top:5px;
	box-sizing: border-box;
	border-bottom:1px solid #eee;
}
.side_tit p a{
	font-size:13px;
	color:#999;
}
.side_tit p a:hover{
	color:#0e82cb;
}
.nen_tits{
	font-size:14px;
	color:#181818;
	display: inline-block;
	line-height: 35px;
}
.menu>li:hover .nen_tits{
	color:#0e82cb;
}
.menu_box{
	position: absolute;
	left:100%;
	top:-30px;
	overflow: hidden;
	width:700px;
	min-height:555px;
	background:#fff;
	padding: 30px 20px 0;
	box-sizing: border-box;
	z-index: 16;
	box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
}
.active3{
	display: none;
}
.menu_box h4{
	padding:5px;
	color:#181818;
	font-size:14px;
	margin-bottom:10px;
	font-weight:600;
}
.menu_box ul{
	padding:5px;
	overflow:hidden;
}
.menu_box ul li{
	width:50%;
	float:left;
	padding-bottom:5px;
}
.menu_box ul li a{
	display: inline-block;
    color: #999;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 27px;
    vertical-align: middle;
}
.menu_box ul li a:hover{
	color:#0e82cb;
}
.menu_box_left{
	width: 500px;
    margin-right: 15px;
    float: left;
}
.menu_box_right{
	width:140px;
	float: right;
}
.menu_box_right dt{
    width: 206px;
    height: 130px;
    border: 1px solid #ededed;
    display: inline-block;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}
.menu_box_right dt:hover{
	border:1px solid #0e82cb;
}
.menu_box_right dt a{
	width:100%;
	height:100%;
	display: inline-block;
	position: relative;
}
.menu_box_right dt a p{
	font-size: 14px;
    color: #999;
    display: block;
}
.menu_box_right dt a span{
	font-size: 14px;
    color: #0e82cb;
    line-height: 35px;
    cursor: pointer;
    text-decoration: none;
}
.menu_box_right dt a img{
	width: 64px;
    height: 64px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.desc_box{
  position:absolute;
  background:#fff;
  padding-top:15px;
  z-index:5;
}
</style>






